import React from 'react'

function Privacypolicy() {
  return (
    <div>
        <p>
            This privacy policy explains how Sri Sai Education Services Limited ("Sri Sai", "we" or "us") collects, uses
        </p>
        <ol>
            <li><strong>Information Collection: </strong>We collect personal information during the registration and enrollment process, ensuring its accuracy and relevance to our educational services. This may include details such as names, contact information, and academic records.</li>
            <li><strong>Types of Information Collected: </strong>Personal information is gathered solely for educational purposes. This encompasses data required for enrollment, communication, and academic support.</li>
            <li><strong>Use of Information: </strong>The personal information we collect is utilized exclusively for educational purposes. This includes facilitating enrollment, maintaining communication channels, and providing necessary academic support to our students.</li>
            <li><strong>Data Security: </strong>To safeguard against unauthorized access, disclosure, alteration, or destruction of personal information, we implement industry-standard security measures. Our commitment is to uphold the confidentiality and integrity of user data.</li>
            <li><strong>Third-Party Services: </strong>Sri Sai Education Services may engage third-party services for analytics or communication purposes. These services operate under their own privacy policies, and we ensure they align with our commitment to user privacy.</li>
            <li><strong>Cookies and Tracking: </strong>Our website may use cookies to enhance user experience. Users have the option to manage cookie preferences by adjusting browser settings. This allows greater control over data shared during the browsing experience.</li>
            <li><strong>Consent: </strong>Users engaging with our services implicitly consent to the terms outlined in this Privacy Policy. This signifies acknowledgment and acceptance of our practices regarding the collection and use of personal information.</li>
            <li><strong>Data Access and Correction: </strong>We acknowledge and respect the rights of users to access and correct their personal information. Requests for such actions can be made through designated channels provided by Sri Sai Education Services.</li>
            <li><strong>Communication: </strong>Collected information may be utilized to communicate with users about educational updates, events, or relevant services. Our communications are tailored to provide valuable and timely information.</li>
            <li><strong>Compliance with Laws: </strong>Sri Sai Education Services is committed to complying with applicable data protection laws and regulations. This ensures that user information is handled in accordance with legal requirements, contributing to a secure and compliant environment.</li>
            <li><strong>Changes to Privacy Policy: </strong>Any modifications to this Privacy Policy will be communicated through our website. Users are encouraged to periodically review the policy to stay informed about how their information is being handled.</li>
            <li><strong>Contact Information: </strong>For any questions or concerns regarding this Privacy Policy, users can contact us at <code>srisaiedu.in@gmail.com</code>. We value user feedback and are dedicated to addressing inquiries promptly.<br/>
By engaging with Sri Sai Education Services, users express their acknowledgment and agreement to the terms outlined in this Privacy Policy. This policy is effective as of <code>31st December 2023</code> and may be updated as necessary to reflect changes in practices or regulations.</li>
        </ol>
    </div>
  )
}

export default Privacypolicy