import React from 'react'

function TermsCondition() {
  return (
    <div>
        <p>
            These terms and conditions govern your use of this website, with effective date the date you first use our site. Please read these terms and conditions carefully
        </p>
        <ol>
            <li><strong>Enrollment and Registration: </strong>Students must provide accurate and complete information during the enrollment process.<br/>Registration fees are non-refundable.</li>
            <li><strong>Course Fees: </strong>Tuition fees are payable in advance, and late payments may incur additional charges.<br/>Fees once paid are non-refundable, except as specified in the refund policy.</li>
            <li><strong>Refund Policy: </strong>Refund requests must be made in writing and are subject to approval.<br/>Refunds, if approved, will be processed within a reasonable timeframe.</li>
            <li><strong>Attendance and Punctuality: </strong>Regular attendance is expected, and students are responsible for making up missed classes.<br/>Punctuality is crucial, and repeated tardiness may result in disciplinary action.</li>
            <li><strong>Code of Conduct: </strong>Students are expected to adhere to a code of conduct that promotes a positive learning environment.<br/>Any form of disruptive behavior may lead to disciplinary measures.</li>
            <li><strong>Academic Integrity: </strong>Plagiarism or cheating in any form is strictly prohibited and may result in academic consequences.</li>
            <li><strong>Termination of Services: </strong>Sri Sai Education Services reserves the right to terminate services for non-compliance with terms and conditions or any disruptive behavior.</li>
            <li><strong>Changes to Courses or Schedule: </strong>Sri Sai Education Services reserves the right to modify course offerings, schedules, or instructors when necessary.</li>
            <li><strong>Privacy and Data Security: </strong>Personal information provided by students will be treated with confidentiality and used for educational purposes only.</li>
            <li><strong>Liability: </strong>Sri Sai Education Services is not liable for any loss, damage, or injury to students' personal property or well-being during the course.</li>
            <li><strong>Governing Law: </strong>These terms and conditions are governed by and construed in accordance with the laws of India, and any disputes will be subject to the exclusive jurisdiction of the courts in India.</li>
        </ol>
    </div>
  )
}

export default TermsCondition