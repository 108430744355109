import React from 'react'
import { useRef, useState } from 'react'
import Emailjs from '@emailjs/browser'

function Enquiry() {

    const [selectedClass, setSelectedClass] = useState('defaultclass');
    const [selectedGroup, setSelectedGroup] = useState('defaultgroup')

    const handleClassChange = (st) => {
        setSelectedClass(st.target.value);
    };

    const handleGroupChange = (st) => {
        setSelectedGroup(st.target.value)
    }



    const forwardEmail = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
      
        if (form.checkValidity()) {
          const name = document.getElementById('InputName').value;
          const email = document.getElementById('InputEmail').value;
          const contact = document.getElementById('InputContact').value;
          const state = document.getElementById('InputState').value;
          const school = document.getElementById('InputSchool').value;
          const board = document.getElementById('InputBoard').value;
          const selectedClass = document.getElementById('InputClass').value;
          const group = document.getElementById('InputGroup')
            ? document.getElementById('InputGroup').value
            : '';
          const othergroup = document.getElementById('EnteredGroup')
            ? document.getElementById('EnteredGroup').value
            : '';
          const known = document.getElementById('InputKnown').value;
          const description = document.getElementById('InputDesc').value;
      
          const templateParams = {
            name,
            email,
            contact,
            state,
            school,
            board,
            selectedClass,
            group,
            othergroup,
            known,
            description,
          };
      
          Emailjs.send('service_kb38nbc', 'template_baif8vp', templateParams, 'VjBB_ywg80DgWY14V')
            .then(
              (response) => {
                console.log('Email sent successfully:', response);
                alert('Email sent successfully!');
                form.reset();
                form.classList.remove('was-validated'); // Reset validation state
              },
              (error) => {
                console.error('Email sending failed:', error);
                alert('Error sending email. Please try again later.');
              }
            );
        } else {
          e.stopPropagation();
        }
      
        form.classList.add('was-validated');
      };
      


  return (
    <div className='enquiry-container pt-5'>
      <h2 className='text-center theme-gradient fw-bolder fs-2'><i className="bi bi-envelope-paper"></i> Contact Us</h2>
      <p className='contact-desc text-center mt-3 mb-4'>Reach out to us by filling out the contact form below, We are eager to assist you on your educational journey!</p>
      <div className='contact-form-img-container'>
        <div className='contact-img-container'>
            <div className='contact-social-icon-container'>
                <i className='bi bi-instagram' onClick={()=>window.open('https://www.instagram.com/sri_sai_edu.in/', '_blank')}></i>
                <i className='bi bi-twitter' onClick={()=>window.open('https://x.com/service_sr44118?t=m-J7ttdzWhltC6f9Up4UGw&s=08', '_blank')}></i>
                <i className='bi bi-envelope-at' onClick={()=>window.open('mailto:srisaiedu.in@gmail.com', '_blank')}></i>
            </div>
        </div>
        <div className='form-container'>
            <form className='contact-form needs-validation' noValidate onSubmit={forwardEmail}>
            <h3 className='form-desc'>Lets Talk a step towards your career</h3>
            <div className="mb-3">
                <label htmlFor="InputName" className="form-label">Name</label>
                <input type="text" placeholder='Eg: Full Name' className="form-control" id="InputName" required/>
                <div className="invalid-feedback">
                    Please choose a username.
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="InputEmail" className="form-label">Email</label>
                <input type="email" placeholder='Eg: yourname@mail.com' className="form-control" id="InputEmail" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputContact" className="form-label">Contact Number</label>
                <input type="number" placeholder='Eg: +91 98765 43210' className="form-control" id="InputContact" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputState" className="form-label">State (as per Aadhaar)</label>
                <input type="text" placeholder='Eg: Tamil Nadu' className="form-control" id="InputState" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputSchool" className="form-label">School/ Institution Name</label>
                <input type="text" placeholder='Eg: Your Schoolname' className="form-control" id="InputSchool" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputBoard" className="form-label">Board of Education</label>
                <input type="text" placeholder='Eg: CBSE' className="form-control" id="InputBoard" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputClass" className="form-label">Class</label>
                <select className="form-select" id="InputClass" value={selectedClass}
          onChange={handleClassChange} required>
                <option value='defaultclass' disabled>Choose class from here</option>
                <option value='10th'>10th</option>
                <option value='12th'>12th</option>
                <option value='LateralDiploma'>Lateral Diploma</option>
                <option value='LateralEngineering'>Lateral Engineering</option>
                </select>
            </div>
            {selectedClass === '12th' && (
            <div className="mb-3">
                <label htmlFor="InputGroup" className="form-label">Group</label>
                <select className="form-select" id="InputGroup" value={selectedGroup} onChange={handleGroupChange} required>
                <option value='defaultgroup' disabled>Choose group from here</option>
                <option value='BiologyMaths'>Biology, Maths</option>
                <option value='BiologyComputer'>Biology, Computer Science</option>
                <option value='ComputerMaths'>Computer Science, Maths</option>
                <option value='commerceMaths'>Commerce, Maths</option>
                <option value='cocational'>Vocational</option>
                <option value='Others'>Others</option>
                </select>
                {selectedGroup === 'Others' && (
                    <input type="text" placeholder='Eg: Science' className="form-control" id="EnteredGroup" required/>
                )}
            </div>
            )}
            <div className="mb-3">
                <label htmlFor="InputKnown" className="form-label">How you know about us?</label>
                <input type="text" placeholder='Eg: From google search' className="form-control" id="InputKnown" required/>
            </div>
            <div className="mb-3">
                <label htmlFor="InputDesc" className="form-label">Description</label>
                <textarea placeholder='Eg: Im Looking for best college in coimbatore for studying B.Sc. Computer Science' className="form-control" id="InputDesc" rows="3" required></textarea>
            </div>
            <button type='submit' className='btn btn-primary rounded-pill px-3'>Submit</button>
            </form>
        </div>
      </div>
    </div>
  )
}

export default Enquiry