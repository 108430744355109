import React from 'react'
import Coaching from '../assets/coaching.jpg'
import WhyChooseUs from '../assets/why-choose-us.jpg'
import Team from '../assets/team work.jpg'

function About() {
  return (
    <div className='About-container'>

      <h2 className='text-center mt-5 theme-gradient fw-bolder fs-2'><i className="bi bi-buildings"></i> ABOUT US</h2>
        <div className='about-info-container'>
          <p>
            Sri Sai Educational Service is your dedicated partner in career guidance, boasting over a decade of expertise. Our mission is to help you navigate the path to your dream profession with practical experiences and valuable insights into global trends. Key highlights:
          </p>
          <ul>
            <li><b className='text-primary'>Guidance Commitment: </b>Dedicated to steering young minds toward optimal career choices.</li>
            <li><b className='text-primary'>Expert Team: </b>Knowledgeable consultants in universities, colleges, and emerging trends.</li>
            <li><b className='text-primary'>Informed Decisions: </b>Empowering students to make informed choices for their future.</li>
            <li><b className='text-primary'>Admission Support: </b>Personalized guidance through the admission process.</li>
            <li><b className='text-primary'>Decade-Long Journey: </b>Over 10 years of experience in shaping successful careers.</li>
          </ul>
        </div>


        {/* Start of Mobile Carousel */}
        <div id="AboutCarousel-sm" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {/* ------------------------------------------------------------------------------- */}
            <div className="carousel-item active about-slide">
              
              <div className='about-card-left d-flex justify-content-center align-items-center'>
                <div className='about-content-left'>
                  <div className='inner-about-content-left d-flex justify-content-center align-items-center'>
                    <div>
                      <h3 className='text-center mb-3'><i className="bi bi-stars"></i> Why Choose us?</h3>
                        <ol>
                          <li><b>Unmatched Expertise: </b>Our seasoned professionals possess in-depth knowledge of college admissions.</li>
                          <li><b>Demystifying the Maze: </b>We provide clear guidance and support, making your journey seamless.</li>
                          <li><b>Perfect Fit Discovery: </b>Align with your academic and career goals through our thorough exploration process.</li>
                          <li><b>Unwavering Support: </b>We offer unwavering support and comprehensive assistance for confident navigation through admissions.</li>
                        </ol>
                    </div>
                  </div>
                </div>
                <img className='about-img-left' src={WhyChooseUs} alt='srisai-about'/>
              </div>
    
            </div>
            {/* ------------------------------------------------------------------------ */}
            <div className="carousel-item about-slide" data-bs-interval="2000">
              
              <div className='about-card-right d-flex justify-content-center align-items-center'>
                <img className='about-img-right' src={Team} alt='srisai-about'/>
                <div className='about-content-right'>
                  <div className='inner-about-content-right d-flex justify-content-center align-items-center'>
                    <div>
                      <h3 className='text-center mb-3'><i className="bi bi-bar-chart-line"></i> Mission</h3>
                      <p>
                        Sri Sai Educational Service's mission is to guide every aspiring student in choosing a thriving profession from a constellation of possibilities. Through expert guidance, we propel students toward fulfilling careers that align with their dreams and ambitions.
                      </p>
                      <h3 className='text-center my-3'><i className="bi bi-bullseye"></i> Vision</h3>
                      <p>
                        Empowering every student to unlock their potential and achieve their academic and professional dreams through expert guidance, unwavering support, and boundless opportunity. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* ------------------------------------------------------------------------ */}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#AboutCarousel-sm" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#AboutCarousel-sm" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* End of Mobile Carousel */}



        {/* Start of Tablet & Desktop Carousel */}
        <div id="AboutCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active about-slide" id='about-first-carousel' data-bs-interval="10000">
              {/* ------------------------------------------------------------------------------------- */}
              <div className='about-card-right d-flex justify-content-center align-items-center'>
                <img className='about-img-right' src={Coaching} alt='srisai-about'/>
                <div className='about-content-right'>
                  <div className='inner-about-content-right d-flex justify-content-center align-items-center'>
                    <div>
                      <p>
                        Sri Sai Educational Service is your dedicated partner in career guidance, boasting over a decade of expertise. Our mission is to help you navigate the path to your dream profession with practical experiences and valuable insights into global trends. Key highlights:
                      </p>
                        <ul>
                          <li><b>Guidance Commitment: </b>Dedicated to steering young minds toward optimal career choices.</li>
                          <li><b>Expert Team: </b>Knowledgeable consultants in universities, colleges, and emerging trends.</li>
                          <li><b>Informed Decisions: </b>Empowering students to make informed choices for their future.</li>
                          <li><b>Admission Support: </b>Personalized guidance through the admission process.</li>
                          <li><b>Decade-Long Journey: </b>Over 10 years of experience in shaping successful careers.</li>
                        </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* ------------------------------------------------------------------------------- */}
            <div className="carousel-item about-slide">
              
              <div className='about-card-left d-flex justify-content-center align-items-center'>
                <div className='about-content-left'>
                  <div className='inner-about-content-left d-flex justify-content-center align-items-center'>
                    <div>
                      <h3 className='text-center mb-3'><i className="bi bi-stars"></i> Why Choose us?</h3>
                        <ol>
                          <li><b>Unmatched Expertise: </b>Our seasoned professionals possess in-depth knowledge of college admissions.</li>
                          <li><b>Demystifying the Maze: </b>We provide clear guidance and support, making your journey seamless.</li>
                          <li><b>Perfect Fit Discovery: </b>Align with your academic and career goals through our thorough exploration process.</li>
                          <li><b>Unwavering Support: </b>We offer unwavering support and comprehensive assistance for confident navigation through admissions.</li>
                        </ol>
                    </div>
                  </div>
                </div>
                <img className='about-img-left' src={WhyChooseUs} alt='srisai-about' />
              </div>
    
            </div>
            {/* ------------------------------------------------------------------------ */}
            <div className="carousel-item about-slide" data-bs-interval="2000">
              
              <div className='about-card-right d-flex justify-content-center align-items-center'>
                <img className='about-img-right' src={Team} alt='srisai-about'/>
                <div className='about-content-right'>
                  <div className='inner-about-content-right d-flex justify-content-center align-items-center'>
                    <div>
                      <h3 className='text-center mb-3'><i className="bi bi-bar-chart-line"></i> Mission</h3>
                      <p>
                        Sri Sai Educational Service's mission is to guide every aspiring student in choosing a thriving profession from a constellation of possibilities. Through expert guidance, we propel students toward fulfilling careers that align with their dreams and ambitions.
                      </p>
                      <h3 className='text-center my-3'><i className="bi bi-bullseye"></i> Vision</h3>
                      <p>
                        Empowering every student to unlock their potential and achieve their academic and professional dreams through expert guidance, unwavering support, and boundless opportunity. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* ------------------------------------------------------------------------ */}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#AboutCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#AboutCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/* End of Tablet & Desktop Carousel */}

      </div>
  )
}

export default About