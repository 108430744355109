import React from 'react'

function Pagenotfound() {
  return (
    <div className='text-center'>
        <h1 className='text-primary mt-5'>404 Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
        <button className='btn btn-primary rounded-pill' onClick={()=>{window.open('https://srisaiedu.vercel.app/')}}>Goto Homepage</button>
    </div>
  )
}

export default Pagenotfound