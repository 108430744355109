import React from 'react'
import { Link, Element } from 'react-scroll'
import logo from '../assets/sri sai logo.svg'
import darklogo from '../assets/sri sai logo dark.svg'
import ProcessFlowSM from '../assets/Srisaiprocess-1.svg'
import ProcessFlowLG from '../assets/Srisaiprocess-2.svg'
import About from './About';
import CustomService from './Services'
import Gallery from './Gallery';
import Courses from './Courses'
import Testimonials from './Testimonials';
import EnquiryBlock from './Enquiry'
import Privacypolicy from './Privacypolicy'
import TermsCondition from './TermsCondition'

function Home() {



  return (
    <div className='spa-container'>
      <Element name='Home-element'>
    
        {/* Start of Navbar */}
      <header>
        <img className='logo' src={logo} alt='srisai'/>
        <div className='lg-menu-container'>
            {/* <Link to="Home-element" smooth={true} duration={50} className='lg-menu'>
                Home
            </Link> */}
            <Link to="About-element" smooth={true} duration={50} className='lg-menu'>
                About us
            </Link>
            <Link to="Service-element" smooth={true} duration={50} className='lg-menu'>
                Our Services
            </Link>
            <Link to="Courses-element" smooth={true} duration={50} className='lg-menu'>
              Offered Courses
            </Link>
            <Link to="Testimonial-element" smooth={true} duration={50} className='lg-menu'>
                Testimonial
            </Link>
        </div>
        <div className="dropdown">
          {/* <a className="btn btn-secondary dropdown-toggle" href="#" >
            Dropdown link
          </a> */}
          <i className="bi bi-list menu-icon" role="button" data-bs-toggle="dropdown" aria-expanded="false"></i>

          <ul className="dropdown-menu">
            
                <li><Link to="Home-element" smooth={true} duration={50} className="dropdown-item">Home</Link></li>
            
            <Link to="About-element" smooth={true} duration={50}>
                <li className="dropdown-item">About Us</li>
            </Link>
            <Link to="Service-element" smooth={true} duration={50}>
                <li className="dropdown-item">Our Services</li>
            </Link>
            <Link to="Courses-element" smooth={true} duration={50}>
                <li className="dropdown-item">Offered Courses</li>
            </Link>
            <Link to="Testimonial-element" smooth={true} duration={50}>
                <li className="dropdown-item">Testimonials</li>
            </Link>
            <Link to="Contact-element" smooth={true} duration={50}>
                <li className="btn btn-primary rounded-pill mx-3 px-3">Enquire Now</li>
            </Link>
          </ul>
        </div>
      </header>

      {/* End of Navbar */}





      {/* Start of Landing page */}
      <div className='home-container'>
          <h1 className='theme-gradient fw-bolder fs-1'>Sri Sai Educational service</h1>
          <p className='text-light'>- The place where your dreams come true!</p>
          <p className='mx-2 text-light fs-5'>Discover your path to success with Sri Sai Educational Service. With over a decade of expertise, our team guides you toward informed decisions, offering personalized support through every step. Unlock your potential and shape a future aligned with your aspirations.</p>
          <Link to="Contact-element" smooth={true} duration={50} className='btn btn-primary rounded-pill fs-5 px-4'>
            Enquire Now
          </Link>
          
      </div>
      {/* End of Landing page */}
      </Element>






      {/* Start of About Container */}
      <Element name='About-element'>
      <About/>

        
      {/* End of About Container */}
      





    <div className='dot-bg'>
      {/* Start of Achievements block */}
      <div className='achievement-container text-center text-secondary pt-4 pb-5'>
        <h3 className='theme-gradient fw-bolder mt-5'>ACHIEVEMENTS</h3>
        <p className='m-0 px-1'><i className="bi bi-award-fill text-warning fs-4"> </i>We have received the <b>best Educational consultant award 3 times.</b></p>
        <div className='achievement-card-container d-flex justify-content-center align-items-center flex-wrap'>
          <div className='achievement-card'>
            <p className='achievement-card-count'>1500+</p>
            <p className='achievement-card-title'>Career Guidance Reach</p>
          </div>
          <div className='achievement-card'>
            <p className='achievement-card-count'>60+</p>
            <p className='achievement-card-title'>Institutional Visits</p>
          </div>
          <div className='achievement-card'>
            <p className='achievement-card-count'>1080+</p>
            <p className='achievement-card-title'>Dream College Guidance</p>
          </div>
          <div className='achievement-card'>
            <p className='achievement-card-count'>340+</p>
            <p className='achievement-card-title'>Scholarship Assistance</p>
          </div>
        </div>
      </div>
      {/* End of Achievements block */}







      {/* Start of Organization process */}
      <div className='organization-container px-3'>
      <h3 className='theme-gradient fw-bolder mt-5 text-center'>ORGANIZATION PROCESS</h3>
          <img className='w-100 process-diagram py-3' src={ProcessFlowSM} alt='srisai-process'/>
          <img className='w-100 oranization-diagram py-3' src={ProcessFlowLG}  alt='srisai-process'/>
      </div>
      {/* End of Organization process */}
    </div>
    </Element>



          <Element name='Service-element'>
            <CustomService/>
            <Gallery/>
        </Element>

        <Element name='Courses-element'>
            <Courses/>
        </Element>


          <Element name='Testimonial-element'>
            <Testimonials/>
          </Element>
            
          <Element name='Contact-element'>
            <EnquiryBlock/>
          </Element>

          

          <footer className='bg-primary text-light p-1 w-100'>
            <div className='footer-top'>
              <div className='footer-address-container'>
                <img className='logo mx-auto' src={darklogo} alt='srisai'/>
                <div>
                  <h3>Sri Sai Education</h3>
                  <p className='ms-2'>94 A Kamaraj Nagar,<br/>Mettupalayam,<br/>Coimbatore - 641305</p>
                </div>
              </div>
              <div className='quick-link-container'>
                <h3 className='fs-5 quick-link-title'>Quick Links</h3>
                <Link to="Home-element" smooth={true} duration={50} className='quick-link'>
                    Home
                </Link>
                <Link to="About-element" smooth={true} duration={50} className='quick-link'>
                    About Us
                </Link>
                <Link to="Service-element" smooth={true} duration={50} className='quick-link'>
                    Services
                </Link>
                <Link to="Courses-element" smooth={true} duration={50} className='quick-link'>
                    Courses
                </Link>
                <Link to="Testimonial-element" smooth={true} duration={50} className='quick-link'>
                    Testimonials
                </Link>
                <Link to="Contact-element" smooth={true} duration={50} className='quick-link'>
                    Contact Us
                </Link>
              </div>
            </div>
            <div className='text-center'>
              <div className='d-flex flex-wrap justify-content-center'>
                <a className='quick-link text-light px-2' type="button" data-bs-toggle="modal" data-bs-target="#privacyModal">Privacy & Policy</a> |
                <a className='quick-link text-light px-2' type="button" data-bs-toggle="modal" data-bs-target="#termsModal">Terms & Condition</a>
              </div>
              <p className='m-0'>© 2023 Copyrights @ Sri Sai Education</p>
            </div>
          </footer>

        {/* Start of Privacy policy Modal */}
        <div className="modal fade" id="privacyModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Privacy Policy - Sri Sai Education Service</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <Privacypolicy/>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
        {/* End of Privacy policy Modal */}

        {/* Start of Terms and Condition Modal */}
        <div className="modal fade" id="termsModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Terms & Condition - Sri Sai Education Service</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <TermsCondition/>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>
        {/* End of Terms and Condition Modal */}

        <div className='Backtotop-container me-3 bg-primary rounded-pill p-0'>
          <Link to="Home-element" smooth={true} duration={50}>
            <i className="bi bi-arrow-up-circle backtotop fs-1 text-center text-light d-flex align-items-center justify-content-center"></i>
          </Link>
        </div>
        {/* End of Back top Top */}

      
    </div>
  )
}

export default Home