import React from 'react'
import SlideA1 from '../assets/slide-a1.jpeg'
import SlideA2 from '../assets/slide-a2.jpeg'
import SlideA3 from '../assets/slide-a3.jpeg'
import SlideB1 from '../assets/slide-b1.jpeg'
import SlideB2 from '../assets/slide-b2.jpeg'
import SlideC1 from '../assets/slide-c1.jpeg'
import SlideC2 from '../assets/slide-c2.jpeg'



function Gallery() {
  return (
    <div className='galler-container pt-5'>
        <h2 className='text-center theme-gradient fw-bolder fs-2'><i className="bi bi-images"></i> Gallery</h2>
        <div className='gallery-slide-container'>


            <div id="galleryCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active gallery-slide-item">
                        <div className='gallery-slide-one gallery-slide'>
                            <div className='gallery-slide-img-container'>
                                <div className='slide-one-top-block'>
                                    <img src={SlideA1} className='slide-a1 slide-img' alt='srisai-gallery'/>
                                    <img src={SlideA2} className='slide-a2 slide-img' alt='srisai-gallery'/>
                                </div>
                                <div className='slide-one-bottom-block'>
                                    <img src={SlideA3} className='slide-a3 slide-img' alt='srisai-gallery'/>
                                </div>
                            </div>
                            <p className='text-center my-3 slide-desc'>“Career guidance program on " IMPORTANCE OF CAREER COUNSELLING" organised by Government Higher secondary school, Ramanathapuram.”</p>
                        </div>
                    </div>
                    <div className="carousel-item gallery-slide-item">
                        <div className='gallery-slide-two gallery-slide'>
                            <div className='gallery-slide-img-container'>
                                <img src={SlideB1} className='slide-b1 slide-img' alt='srisai-gallery'/>
                            </div>
                            <p className='text-center my-3 slide-desc'>“Sri Sai Education's Chairman honouring the Head master of Government Higher secondary school, Ramanathapuram for organising a career counseling program on January 5th of 2023.”</p>
                        </div>
                    </div>
                    <div className="carousel-item gallery-slide-item">
                        <div className='gallery-slide-three gallery-slide'>
                            <div className='gallery-slide-img-container d-flex flex-wrap justify-content-between'>
                                <img src={SlideC1} className='slide-c1 slide-img' alt='srisai-gallery'/>
                                <img src={SlideC2} className='slide-c2 slide-img' alt='srisai-gallery'/>
                            </div>
                            <p className='text-center my-3 slide-desc'>“Students honoured our chairman with an unexpected compliment at Pollachi student counselling meet.”</p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#galleryCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#galleryCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>


            
            
            
        </div>
    </div>
  )
}

export default Gallery
