import React, { useState, useEffect } from 'react';

function Services() {

    const serviceData = [
        {
          heading: 'Expert Counselling Service',
          content: `Seeking expert guidance, to overcome challenges or reach your full potential? Look no further than Sri Sai Educational Service's diverse range of Expert counseling services designed to support you every step of the way. Whether you prefer the convenience of online Sessions or the personal touch of traditional in-person meetings, we offer solutions tailored to your needs. Let Sri Sai Educational Service's Expert counseling Services be your compass. Contact us today for a free consultation and discover the support you deserve.`,
        },
        {
          heading: 'Scholarship Assistance',
          content: `Financial anxieties shouldn't dim your academic aspirations. At Sri Sai Educational Service, we believe every deserving student deserves a chance to flourish, and that's why we offer comprehensive scholarship Assistance services. We provide scholarship assistance for Tamil Nadu & other state students based on their Academic performance and also give importance to the students from the Sports background.`,
        },
        {
          heading: 'Admission Guidance',
          content: `Navigating the intricate admissions landscape of Tamil Nadu can feel like traversing a complex maze. But fear not, aspiring scholar! Sri Sai Educational Service is here to be your trusty compass, guiding you through every twist and turn with our Encompassing admission guidance. We provide Admission guidance for Xth, XIIth, and Diploma students. And also we guide undergraduate students for doing their Master's in reputed Institutions.`,
        },
        {
          heading: 'Placement Assistance',
          content: `We help to discover the right path for landing in your dream job. Sri Sai Educational Service imparts the knowledge what the industry needs for the upcoming decades. We not only guide you, we follow you until you reach your goals. For placement assistance send your Resume or CV to our Mail.`,
        },
      ];
    
      const [selectedHeading, setSelectedHeading] = useState(0);
    
      useEffect(() => {
        const handleScroll = () => {
          const contentsContainer = document.querySelector('.contents-container');
          const scrollPosition = contentsContainer.scrollTop;
          const contentHeight = contentsContainer.scrollHeight;
          const containerHeight = contentsContainer.clientHeight;
    
          // Calculate the index of the heading based on scroll position
          const newIndex = Math.ceil((scrollPosition / (contentHeight - containerHeight)) * serviceData.length)-1

          if(newIndex !== selectedHeading) {
            // Update the selected heading if it's different
            if(newIndex<0){
              setSelectedHeading(0)
            }else if(newIndex>=serviceData.length){
              setSelectedHeading(serviceData.length-1)
            }else{
              setSelectedHeading(newIndex-1);
            }
            
          }
        };
    
        // Attach the scroll event listener
        document.querySelector('.contents-container').addEventListener('scroll', handleScroll);
    
        // Remove the event listener when the component unmounts
        return () => {
          document.querySelector('.contents-container').removeEventListener('scroll', handleScroll);
        };
      }, [selectedHeading, serviceData]);
    
      const handleHeadingClick = (index) => {
        setSelectedHeading(index);
        document.querySelector('.contents-container').scrollTop = index * 130; // Adjust as needed
      };

  return (
    <div className='service-component-container-bg'>
        <div className='service-component-container-body'>
          <h2 className='text-center mt-5 theme-gradient fw-bolder fs-2'><i className="bi bi-duffle"></i> We provide</h2>
          <div className="service-component-container">
            <div className="headings-container">
                {serviceData.map((item, index) => (
                <div
                    key={index}
                    className={`heading ${selectedHeading === index?'active' : ''}`}
                    onClick={() => handleHeadingClick(index)}
                >
                    {item.heading}
                </div>
                ))}
            </div>
            <div className="contents-container">
                {serviceData.map((item, index) => (
                <div key={index} className="content">
                  <p className='content-heading'>{item.heading}</p>
                  <p className='content-desc'>{item.content}</p>
                </div>
                ))}
            </div>
          </div>

          <div className="accordion mt-3" id="serviceAccordion">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#serviceOne" aria-expanded="true" aria-controls="collapseOne">
                  <strong className='text-primary'>Expert Counselling Service</strong>
                </button>
              </h2>
              <div id="serviceOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Seeking expert guidance, to overcome challenges or reach your full potential? Look no further than Sri Sai Educational Service's diverse range of Expert counseling services designed to support you every step of the way. Whether you prefer the convenience of online Sessions or the personal touch of traditional in-person meetings, we offer solutions tailored to your needs. Let Sri Sai Educational Service's Expert counseling Services be your compass. Contact us today for a free consultation and discover the support you deserve.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#serviceTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <strong className='text-primary'>Scholarship Assistance</strong>
                </button>
              </h2>
              <div id="serviceTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Financial anxieties shouldn't dim your academic aspirations. At Sri Sai Educational Service, we believe every deserving student deserves a chance to flourish, and that's why we offer comprehensive scholarship Assistance services. We provide scholarship assistance for Tamil Nadu & other state students based on their Academic performance and also give importance to the students from the Sports background.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#serviceThree" aria-expanded="false" aria-controls="collapseThree">
                  <strong className='text-primary'>Admission Guidance</strong>
                </button>
              </h2>
              <div id="serviceThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  Navigating the intricate admissions landscape of Tamil Nadu can feel like traversing a complex maze. But fear not, aspiring scholar! Sri Sai Educational Service is here to be your trusty compass, guiding you through every twist and turn with our Encompassing admission guidance. We provide Admission guidance for Xth, XIIth, and Diploma students. And also we guide undergraduate students for doing their Master's in reputed Institutions.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#serviceFour" aria-expanded="false" aria-controls="collapseThree">
                  <strong className='text-primary'>Placement Assistance</strong>
                </button>
              </h2>
              <div id="serviceFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  We help to discover the right path for landing in your dream job. Sri Sai Educational Service imparts the knowledge what the industry needs for the upcoming decades. We not only guide you, we follow you until you reach your goals. For placement assistance send your Resume or CV to our Mail.
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>
  )
}

export default Services