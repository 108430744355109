import React from 'react'
import Rahuldp from '../assets/rahuldp.jpeg'

function Testimonials() {
  return (
    <div className='testimonial-container'>
        <h2 className='text-center theme-gradient fw-bolder fs-2 mt-5 mb-4'><i className="bi bi-chat-square-quote"></i> Testimonials</h2>
        <div className='testimonial-slider-container'>


            <div id="TestimonialCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">

                        <div className='testimonial-slide'>
                            <h3 className='text-center text-primary'>Key to my Academic success!</h3>
                            <div className='testimonial-star-container text-warning text-center my-2'>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                            </div>
                            <div className='testimonial-bottom-container'>
                                <div className='testimonial-dp-container text-center'>
                                    <img className='testimonial-dp' src='https://ui-avatars.com/api/?name=Rahul+Raaj&background=0D8ABC&color=fff' alt='v.a. rahul raaj'/>
                                    {/* <div className='text-center'>
                                        <i className='bi bi-linkedin testimonial-social-icons'></i>
                                        <i className='bi bi-instagram testimonial-social-icons'></i>
                                        <i className='bi bi-envelope testimonial-social-icons'></i>
                                    </div> */}

                                </div>
                                <div className='testimonial-content-container'>
                                    <p className='testimonial-desc'>Personalized guidance and unwavering support truly made a difference.<br/>
                                    "Exceptional services from Sri Sai Education! Grateful for their commitment to a positive learning environment. Highly recommended for quality education support.
                                    Proudly associated with Sri Sai Education Services. Their commitment to educational excellence and tech integration sets them apart!
                                    Kudos to Sri Sai Education for invaluable career guidance and a comprehensive approach to academic excellence. Shaping successful futures!
                                    Test success achieved! Sri Sai Education's expert faculty and personalized attention made all the difference. Highly recommend their test prep services.
                                    Thriving academically and personally with Sri Sai Education Services. Innovative methods and experienced faculty create a standout learning environment."
                                    </p>
                                    <p className='testimonial-author text-end'>- V.A. Rahul Raaj</p>
                                    <p className='testimonial-designation text-end'>Senior Executive, Chennai</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="carousel-item" data-bs-interval="2000">

                        <div className='testimonial-slide'>
                            <h3 className='text-center text-primary'>Parent Recommendation</h3>
                            <div className='testimonial-star-container text-warning text-center my-2'>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                            </div>
                            <div className='testimonial-bottom-container'>
                                <div className='testimonial-dp-container text-center'>
                                    <img className='testimonial-dp' src='https://ui-avatars.com/api/?name=Sri+Hari&background=0D8ABC&color=fff' alt='Sri Hariharan'/>
                                    {/* <div className='text-center'>
                                        <i className='bi bi-linkedin testimonial-social-icons'></i>
                                        <i className='bi bi-instagram testimonial-social-icons'></i>
                                        <i className='bi bi-envelope testimonial-social-icons'></i>
                                    </div> */}

                                </div>
                                <div className='testimonial-content-container'>
                                    <p className='testimonial-desc'>
                                    "Grateful for the exceptional services provided by Sri Sai Education Services. Their commitment to fostering a positive learning environment and ensuring my child's academic growth is evident. Highly recommended for quality education support."<br/>
                                    "Working with Sri Sai Education Services has been rewarding. Their commitment to educational excellence and seamless integration of technology in learning make them stand out. Proud to be associated with a service prioritizing student success."
                                    </p>
                                    <p className='testimonial-author text-end'>- Sri Hariharan</p>
                                    <p className='testimonial-designation text-end'>Business Manager, Coimbatore</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="carousel-item" data-bs-interval="2000">

                        <div className='testimonial-slide'>
                            <h3 className='text-center text-primary'>I'm here because of Sri Sai Educational Service</h3>
                            <div className='testimonial-star-container text-warning text-center my-2'>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                            </div>
                            <div className='testimonial-bottom-container'>
                                <div className='testimonial-dp-container text-center'>
                                    <img className='testimonial-dp' src='https://ui-avatars.com/api/?name=Nimesh&background=0D8ABC&color=fff' alt='Nimesh'/>
                                    {/* <div className='text-center'>
                                        <i className='bi bi-linkedin testimonial-social-icons'></i>
                                        <i className='bi bi-instagram testimonial-social-icons'></i>
                                        <i className='bi bi-envelope testimonial-social-icons'></i>
                                    </div> */}
                                </div>
                                <div className='testimonial-content-container'>
                                    <p className='testimonial-desc'>
                                        Individual attention from the college studies to industry make me to reach the destination in a right way. Thank you team of Sri Sai Educational service for your valuable support in all the scenario.
                                    </p>
                                    <p className='testimonial-author text-end'>- Nimesh</p>
                                    <p className='testimonial-designation text-end'>Instrumentation engineer, Doha</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="carousel-item" data-bs-interval="2000">

                        <div className='testimonial-slide'>
                            <h3 className='text-center text-primary'>Sri Sai Educational Service is my Cheerleaders</h3>
                            <div className='testimonial-star-container text-warning text-center my-2'>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                                <i className="bi bi-star-fill testimonial-star"></i>
                            </div>
                            <div className='testimonial-bottom-container'>
                                <div className='testimonial-dp-container text-center'>
                                    <img className='testimonial-dp' src='https://ui-avatars.com/api/?name=Sudharshan+S&background=0D8ABC&color=fff' alt='Sudharsan S'/>
                                    {/* <div className='text-center'>
                                        <i className='bi bi-linkedin testimonial-social-icons'></i>
                                        <i className='bi bi-instagram testimonial-social-icons'></i>
                                        <i className='bi bi-envelope testimonial-social-icons'></i>
                                    </div> */}
                                </div>
                                <div className='testimonial-content-container'>
                                    <p className='testimonial-desc'>
                                        My journey towards achieving my academic dream wasn't a solo act. It was a symphony of confusion, anxieties, and aspirations, orchestrated by the unwavering support of Sri Sai Educational Service. I wasn't just a student seeking admission; I was a lost compass, desperately needing direction. And Sri Sai Educational Service became my North Star, guiding me through every twist and turn with unwavering commitment.

                                        Today, as I stand on the cusp of my academic dream, I look back with immense gratitude. Sri Sai Educational Service wasn't just a service provider; they were my cheerleaders, my confidantes. They didn't just guide me to a university; they guided me to myself.
                                    </p>
                                    <p className='testimonial-author text-end'>- Sudharsan S</p>
                                    <p className='testimonial-designation text-end'>B.Com - Business Analytics, PSG college of Arts and Science</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#TestimonialCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bg-primary rounded" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#TestimonialCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon bg-primary rounded" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>


        </div>

    </div>
  )
}

export default Testimonials