import { Route, Routes } from 'react-router-dom';
import '../css/App.css';
import '../css/bootstrap.css'
import Home from './Home'
import ErrorPage from './Pagenotfound'


function App() {
  
  

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='*' element={<ErrorPage/>} />
      </Routes>
    </div>
  );
}

export default App;
