import React from 'react'

function Courses() {
  return (
    <div className='courses-container'>
      <h2 className='text-center theme-gradient fw-bolder fs-2'><i className="bi bi-book-half"></i> Courses Offered</h2>
      <div className='course-slides-container'>





        <div id="coursesCarousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        
                      <div className='course-slide'>
                        <h3 className='course-title'>BE/ B.TECH</h3>
                        <div className='course-list-container'>
                          <ul className='course-list-partition'>
                              <li>Artificial Intelligence And Data Science</li>
                              <li>Artificial Intelligence And Machine Learning</li>
                              <li>Automation and Robotics</li>
                              <li>Automobiles Engineering</li>
                              <li>Aerospace Engineering</li>
                              <li>Bio Medical Engineering</li>
                              <li>Bio Technology</li>
                              <li>Computer Science & Engineering</li>
                              <li>Computer Science and Business System</li>
                              <li>Cyber Security</li>
                          </ul>
                          <ul className='course-list-partition'>
                            <li>Information Technology</li>
                            <li>Civil Engineering</li>
                            <li>Chemical Engineering</li>
                            <li>Electronics and Communication Engineering</li>
                            <li>Electrical and Electronics Engineering</li>
                            <li>Food Technology</li>
                            <li>Mechanical Engineering</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="carousel-item">
                       
                      <div className='course-slide course-list-container'>
                        <div>
                          <h3 className='course-title'>B.Sc.</h3>
                            <ul className='course-list-partition'>
                              <li>Artificial Intelligence and Data Science</li>
                              <li>Artificial Intelligence and Machine Learning</li>
                              <li>Bio Technology</li>
                              <li>Computer science</li>
                              <li>Computer Science (with specializations)</li>
                              <li>Fashion Designing</li>
                              <li>Information Technology</li>
                              <li>Micro Biology</li>
                              <li>Cyber Security</li>
                              <li>Digital & Cyber Forensic Science</li>
                              <li>Visual Communication</li>
                              <p className='courses-and-more'>& More...</p>
                            </ul>
                        </div>
                        <div>
                          <h3 className='course-title'>BBA</h3>
                          <ul className='course-list-partition'>
                            <li>Aviation Management</li>
                            <li>Retail Management</li>
                            <li>Computer Application</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="carousel-item">
                       
                      <div className='course-slide course-list-container'>
                        <div>
                          <h3 className='course-title'>B.Com.</h3>
                          <ul className='course-list-partition'>
                            <li>Bachelor of Commerce</li>
                            <li>Computer Application</li>
                            <li>Banking and Insurance</li>
                            <li>Accounting and Finance</li>
                            <li>Professional Accounting</li>
                            <li>Information Technology</li>
                            <li>International Business</li>
                            <li>Financial Service specialization in Digital Finance</li>
                            <li>Corporate Secretaryship</li>
                            <li>B.Com(some specializations)</li>
                            <li>B.Com - Finance</li>
                            <li>B.Com - GST</li>
                            <li>Business Process Service(BPS)</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                        <div>
                          <h3 className='course-title'>BA</h3>
                          <ul className='course-list-partition'>
                            <li>English</li>
                            <li>Tamil</li>
                            <li>Political Science</li>
                            <li>Defence and Strategic studies</li>
                            <li>English Self Finance</li>
                            <li>Economics</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <div className="carousel-item">
                       
                      <div className='course-slide course-list-container'>
                        <div>
                          <h3 className='course-title'>Medical Related Courses</h3>
                          <ul className='course-list-partition'>
                            <li>B.Sc Optometry</li>
                            <li>B.Sc. Operation theatre & Anesthesia Technology</li>
                            <li>B.Sc. Cardiac Technology</li>
                            <li>B.Sc. Physician Assistant</li>
                            <li>B.Sc. Dialysis Technology</li>
                            <li>B.Sc. Medical Laboratory Technology</li>
                            <li>B.Sc. Nursing</li>
                            <li>MBBS</li>
                            <li>B.Pharm</li>
                            <li>B.Sc. Psychology</li>
                            <li>B.Sc. Pysiotherapy</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                        <div>
                          <h3 className='course-title'>Post Graduation(PG)</h3>
                          <ul className='course-list-partition'>
                            <li>ME/ M.TECH</li>
                            <li>MBA</li>
                            <li>M.Sc.</li>
                            <li>MCA</li>
                            <p className='courses-and-more'>& More...</p>
                          </ul>
                        </div>
                      </div>

                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#coursesCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon bg-secondary rounded" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#coursesCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon bg-secondary rounded" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>



      </div>
    </div>
  )
}

export default Courses